import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaGoogle, FaFacebook, FaGithub } from 'react-icons/fa';
import { signup } from '../utils/api';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { validateEmail } from '../utils/api';

const SignupPage = () => {
    const [formData, setFormData] = useState({ name: '', email: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [emailValidated, setEmailValidated] = useState(false);
    const [isValidatingEmail, setIsValidatingEmail] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors(prev => ({ ...prev, [e.target.name]: null }));
        if (e.target.name === 'email') {
            setEmailValidated(false);
        }
    };

    const navigate = useNavigate();
    const { login } = useAuth();

    const handleEmailValidation = async () => {
        if (!formData.email) {
            setErrors(prev => ({ ...prev, email: 'Email is required' }));
            return;
        }
        setIsValidatingEmail(true);
        try {
            const isValid = await validateEmail(formData.email);
            if (isValid) {
                setEmailValidated(true);
                setErrors(prev => ({ ...prev, email: null }));
            } else {
                setErrors(prev => ({ ...prev, email: 'Invalid email address' }));
            }
        } catch (error) {
            setErrors(prev => ({ ...prev, email: 'Error validating email. Please try again.' }));
        } finally {
            setIsValidatingEmail(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!emailValidated) {
            setErrors(prev => ({ ...prev, email: 'Please validate your email first' }));
            return;
        }
        setIsLoading(true);
        setErrors({});

        try {
            const response = await signup(formData);
            login(response);
            navigate('/');
            await window.Reflio.signup(formData.email);
        } catch (error) {
            if (error?.response?.data) {
                setErrors(error.response.data);
            } else {
                setErrors({ general: "An unexpected error occurred. Please try again." });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8 relative overflow-hidden">
            {/* ... (previous code) */}
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md z-10">
                <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10"
                >
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        {/* Name field */}
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Full Name
                            </label>
                            <div className="mt-1">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    required
                                    className={`appearance-none block w-full px-3 py-2 border ${errors.name ? 'border-red-300' : 'border-gray-300'} rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                {errors.name && <p className="mt-2 text-sm text-red-600">{errors.name}</p>}
                            </div>
                        </div>

                        {/* Email field */}
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email address
                            </label>
                            <div className="mt-1 flex">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className={`appearance-none block w-full px-3 py-2 border ${errors.email ? 'border-red-300' : emailValidated ? 'border-green-300' : 'border-gray-300'} rounded-l-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    type="button"
                                    onClick={handleEmailValidation}
                                    disabled={isValidatingEmail || emailValidated}
                                    className={`ml-2 inline-flex justify-center py-2 px-4 border border-transparent rounded-r-md shadow-sm text-sm font-medium text-white ${emailValidated ? 'bg-green-600' : 'bg-indigo-600 hover:bg-indigo-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                                >
                                    {isValidatingEmail ? 'Validating...' : emailValidated ? 'Validated' : 'Validate'}
                                </motion.button>
                            </div>
                            <AnimatePresence>
                                {errors.email && (
                                    <motion.p
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: -10 }}
                                        className="mt-2 text-sm text-red-600"
                                    >
                                        {errors.email}
                                    </motion.p>
                                )}
                            </AnimatePresence>
                        </div>

                        {/* Password field */}
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div className="mt-1">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    maxLength={18}
                                    className={`appearance-none block w-full px-3 py-2 border ${errors.password ? 'border-red-300' : 'border-gray-300'} rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                {errors.password && <p className="mt-2 text-sm text-red-600">{errors.password}</p>}
                            </div>
                        </div>

                        {/* Submit button */}
                        <div>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                type="submit"
                                disabled={isLoading || !emailValidated}
                                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${emailValidated ? 'bg-gradient-to-r from-purple-600 to-indigo-600 hover:bg-indigo-700' : 'bg-gray-400'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                            >
                                {isLoading ? 'Signing up...' : 'Sign up'}
                            </motion.button>
                        </div>
                        {errors.general && (
                            <p className="text-red-500 text-sm mt-2">{errors.general}</p>
                        )}

                        <div className="mt-6 text-center">
                            <p className="text-sm text-gray-600">
                                Already have an account?{' '}
                                <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Log in
                                </Link>
                            </p>
                        </div>
                    </form>

                    {/* <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">Or continue with</span>
                            </div>
                        </div>

                        <div className="mt-6 grid grid-cols-3 gap-3">
                            {[
                                { name: 'Google', icon: FaGoogle, color: 'text-red-600' },
                                { name: 'Facebook', icon: FaFacebook, color: 'text-blue-600' },
                                { name: 'GitHub', icon: FaGithub, color: 'text-gray-800' }
                            ].map((provider) => (
                                <motion.div key={provider.name} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>

                                    <a href="#"
                                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                    >
                                        <span className="sr-only">Sign up with {provider.name}</span>
                                        <provider.icon className={`w-5 h-5 ${provider.color}`} />
                                    </a>
                                </motion.div>
                            ))}
                        </div>
                    </div> */}
                </motion.div>
            </div >
        </div >
    );
};

export default SignupPage;