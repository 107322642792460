import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const AUTH_URL = `${BASE_URL}/auth`;
const USER_URL = `${BASE_URL}/user`;
const MAP_URL = `${BASE_URL}/map`;
const CREDIT_URL = `${BASE_URL}/credit`;

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const signup = async (userData) => {
  try {
    const response = await api.post(`${AUTH_URL}/register/`, {
      first_name: userData.name,
      email: userData.email,
      password: userData.password,
    });
    return response.data;
  } catch (error) {
    console.error('Signup error:', error.response?.data || error.message);
    throw error;
  }
};

export const login = async (credentials) => {
  try {
    const response = await api.post(`${AUTH_URL}/login/`, credentials);
    return response.data;
  } catch (error) {
    console.error('Login error:', error.response?.data || error.message);
    throw error;
  }
};

export const getBusinessTypes = async () => {
  try {
    const response = await api.get(`${MAP_URL}/business-types/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching business types:', error);
    throw error;
  }
};

export const getBusinessLeads = async (data, token) => {
  try {
    const response = await api.post(`${MAP_URL}/business-leads/`, data, {
      headers: {
        'Authorization': `Token ${token}`,
      },
    });
    console.log(response?.data, "response");
    return response.data;
  } catch (error) {
    console.error('Business Leads error:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserCredit = async (token) => {
  try {
    const response = await api.get(`${CREDIT_URL}/display-credit/`, {
      headers: {
        'Authorization': `Token ${token}`,
      },
    });
    console.log(response.data, "credit");
    return response.data;
  } catch (error) {
    console.error('Get User Credit error:', error.response?.data || error.message);
    throw error;
  }
};

export const fetchPricingPlans = async () => {
  try {
    const response = await api.get(`${CREDIT_URL}/display-plan/`);
    console.log(response.data, "credit");
    return response.data;
  } catch (error) {
    console.error('Get User Credit error:', error.response?.data || error.message);
    throw error;
  }
};

export const requestPasswordReset = async (username) => {
  try {
    const response = await api.post(`${AUTH_URL}/reset-password-link/`, { username });
    return response.data;
  } catch (error) {
    console.error('Password reset request error:', error.response?.data || error.message);
    throw error;
  }
};

export const resetPassword = async (username, password, confirmPassword) => {
  try {
    const response = await api.post(`${AUTH_URL}/reset-password/`, {
      username,
      password,
      confirm_password: confirmPassword
    });
    return response.data;
  } catch (error) {
    console.error('Password reset error:', error.response?.data || error.message);
    throw error;
  }
};

export const validateEmail = async (email) => {
  try {
    const response = await api.post(`${AUTH_URL}/email-validate/`, { email });
    if (response.data.STATUS === "VALID") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Email validation error:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserProfile = async (token) => {
  try {
    const response = await api.get(`${USER_URL}/user-profile/`, {
      headers: {
        'Authorization': `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Get User Profile error:', error.response?.data || error.message);
    throw error;
  }
};

export const updateUserProfile = async (token, userData) => {
  try {
    const response = await api.patch(`${USER_URL}/user-profile/`, userData, {
      headers: {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Update User Profile error:', error.response?.data || error.message);
    throw error;
  }
};

export const updatePassword = async (token, newPassword, confirmPassword) => {
  try {
    const response = await api.post(`${AUTH_URL}/update-password/`, { new_password: newPassword, confirm_password: confirmPassword }, {
      headers: {
        'Authorization': `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Update Password error:', error.response?.data || error.message);
    throw error;
  }
};