import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { fetchPricingPlans } from '../utils/api';
import LoginPrompt from '../components/LoginPrompt';
import { useLocation } from 'react-router-dom';

const PricingPage = () => {
    const [plans, setPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showLoginPrompt, setShowLoginPrompt] = useState(false);
    const [processingPlanId, setProcessingPlanId] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const { auth } = useAuth();
    const location = useLocation();

    const updateReflioStatus = async (email, amount) => {
        try {
            // await window.Reflio.convert(email, {
            //     status: 'sale',
            //     value: amount
            // });
            await window.Reflio.track('payment', email, amount, {
                metadata: {
                    reflio_referral_id: 'abte83eu6x8su4e'
                }
            });
            console.log('Reflio status updated successfully as a sale');
        } catch (error) {
            console.error('Error updating Reflio status:', error);
        }
    };

    useEffect(() => {
        const loadPlans = async () => {
            try {
                const data = await fetchPricingPlans();
                setPlans(data);
                setIsLoading(false);
            } catch (err) {
                setError('Failed to load pricing plans. Please try again later.');
                setIsLoading(false);
            }
        };
        loadPlans();

        // Check for payment status in URL
        const params = new URLSearchParams(location.search);
        const message = params.get('message');
        const paymentId = params.get('paymentId');
        const amount = params.get('amount'); // Add this line to get the payment amount

        if (message && paymentId) {
            const isSuccess = message.toLowerCase().includes('successful');
            setPaymentStatus({ success: isSuccess, paymentId, message });
            if (isSuccess && auth?.user?.email && amount) {
                updateReflioStatus(auth.user.email, parseFloat(amount));
            }
        }
    }, [location, auth]);

    const handleGetStarted = async (planId) => {
        if (!auth) {
            setShowLoginPrompt(true);
            return;
        }

        setProcessingPlanId(planId);
        setRedirectUrl(true); // Show redirecting popup immediately

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/payment/make-payment/`,
                { plan_id: planId },
                {
                    headers: {
                        'Authorization': `Token ${auth.token}`,
                    },
                }
            );

            if (response.data.status === 'success') {
                // Redirect after a short delay to allow for animation
                window.location.href = response.data.approval_url;
                setRedirectUrl(false);

            } else {
                throw new Error('Unexpected response from server');
            }
        } catch (err) {
            setRedirectUrl(false);
            setShowErrorPopup(true);
        } finally {
            setProcessingPlanId(null);
        }
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3
            }
        }
    };

    const planVariants = {
        hidden: { y: 50, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 100
            }
        }
    };

    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    const popupVariants = {
        hidden: { scale: 0.8, opacity: 0 },
        visible: {
            scale: 1,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 20
            }
        },
        exit: {
            scale: 0.8,
            opacity: 0,
            transition: {
                duration: 0.2
            }
        }
    };

    const handleClosePopup = () => {
        setShowErrorPopup(false);
        setPaymentStatus(null);
    };

    // Update this function to fix to two decimal places
    const calculatePricePerCredit = (price, credits) => {
        return (price / credits).toFixed(2);
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <motion.div
                    className="w-16 h-16 border-t-4 border-indigo-500 border-solid rounded-full animate-spin"
                    animate={{ rotate: 360 }}
                    transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center h-screen">
                <motion.p
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-red-500 text-xl"
                >
                    {error}
                </motion.p>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-50 to-indigo-100 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <motion.div
                    className="text-center"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        Choose the Right Plan for You
                    </h2>
                    <p className="mt-4 text-xl text-gray-600">
                        Get the credits you need to grow your business
                    </p>
                </motion.div>

                <motion.div
                    className="mt-16 grid gap-8 lg:grid-cols-3 lg:gap-x-8"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >
                    {plans.map((plan, index) => (
                        <motion.div
                            key={plan.name}
                            className={`relative rounded-2xl shadow-xl overflow-hidden ${plan.name === 'Premium' ? 'premium-plan' : 'standard-plan'
                                }`}
                            variants={planVariants}
                        >
                            <div className="p-8">
                                <h3 className="text-2xl font-semibold text-gray-900">
                                    {plan.name}
                                </h3>
                                <div className="mt-4 flex items-baseline justify-between">
                                    <span className="text-5xl font-extrabold tracking-tight text-gray-900">
                                        ${plan.price}
                                    </span>
                                    <span className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 text-transparent bg-clip-text">
                                        {plan.credits} credits
                                    </span>
                                </div>
                                <div className="mt-6 p-4 bg-indigo-100 rounded-lg">
                                    <div className="flex justify-between items-center">
                                        <span className="text-lg font-medium text-indigo-800">Price per credit:</span>
                                        <span className="text-2xl font-bold text-indigo-600">
                                            ${calculatePricePerCredit(plan.price, plan.credits)}
                                        </span>
                                    </div>
                                </div>
                                <div className="mt-8 space-y-4">
                                    <h4 className="text-lg font-medium text-gray-900">Features:</h4>
                                    <ul className="space-y-2">
                                        <li className="flex items-center">
                                            <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                            CSV download
                                        </li>
                                        <li className="flex items-center">
                                            <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                            Access to all features
                                        </li>
                                        <li className="flex items-center">
                                            <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                            24/7 customer support
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="px-6 py-8 bg-gray-50 space-y-6 sm:px-10 sm:py-10">
                                <motion.button
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    type="button"
                                    className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={() => handleGetStarted(index + 1)}
                                    disabled={processingPlanId !== null}
                                >
                                    {processingPlanId === index + 1 ? 'Processing...' : 'Get started'}
                                </motion.button>
                            </div>
                        </motion.div>
                    ))}
                    {/* Add the new message here */}
                    <motion.div
                        className="mt-6 text-center lg:col-span-3"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5, duration: 0.5 }}
                    >
                        <p className="text-lg font-medium text-indigo-600 text-center">
                            Want monthly credits? Subscriptions plans coming soon...
                        </p>
                    </motion.div>
                </motion.div>
            </div>

            <AnimatePresence>
                {showLoginPrompt && (
                    <LoginPrompt onClose={() => setShowLoginPrompt(false)} isPricingPage={true} />
                )}
            </AnimatePresence>

            <AnimatePresence>
                {redirectUrl && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                        variants={overlayVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <motion.div
                            variants={popupVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            className="bg-white p-8 rounded-lg shadow-xl"
                        >
                            <p className="text-xl font-semibold mb-4">Redirecting to payment page...</p>
                            <div className="w-12 h-12 border-t-4 border-blue-500 border-solid rounded-full animate-spin mx-auto"></div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {(showErrorPopup || paymentStatus) && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                        variants={overlayVariants}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <motion.div
                            variants={popupVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            className="bg-white p-8 rounded-lg shadow-xl max-w-md"
                        >
                            {showErrorPopup && (
                                <>
                                    <h3 className="text-xl font-semibold mb-4 text-red-600">Oops! Something went wrong</h3>
                                    <p className="text-gray-700 mb-6">
                                        We encountered an unexpected error while processing your request. Please try again or contact support.
                                    </p>
                                </>
                            )}
                            {paymentStatus && (
                                <>
                                    <h3 className={`text-xl font-semibold mb-4 ${paymentStatus.success ? 'text-green-600' : 'text-red-600'}`}>
                                        {paymentStatus.message}
                                    </h3>
                                    <p className="text-gray-700 mb-6">
                                        {paymentStatus.success
                                            ? `Your payment was successful. Payment ID: ${paymentStatus.paymentId}`
                                            : 'We couldn\'t process your payment. Please try again or contact support.'}
                                    </p>
                                </>
                            )}
                            <button
                                onClick={handleClosePopup}
                                className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
                            >
                                Close
                            </button>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default PricingPage;
