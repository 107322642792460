import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Home from './pages/Home';
import PricingPage from './pages/Pricing';
import UnderDevelopment from './components/UnderDevelopment';
import ResetPassword from './pages/ResetPassword';
import Profile from './pages/Profile';
import { useAuth } from './contexts/AuthContext';

// New component for protected routes
const ProtectedRoute = ({ children }) => {
  const { auth } = useAuth();
  
  if (!auth) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function AppRouter() {
    const navbarRef = useRef();

    const handleCreditUpdate = (newCredit) => {
        if (navbarRef.current && navbarRef.current.updateCredit) {
            navbarRef.current.updateCredit(newCredit);
        }
    };

    return (
        <Router>
            <Navbar ref={navbarRef} />
            <Routes>
                <Route path="/" element={
                    <ProtectedRoute>
                        <Home onCreditUpdate={handleCreditUpdate} />
                    </ProtectedRoute>
                } />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/about" element={<UnderDevelopment />} />
                <Route path="/profile" element={
                    <ProtectedRoute>
                        <Profile />
                    </ProtectedRoute>
                } />
                <Route path="/reset-password/:email/:token" element={<ResetPassword />} />
                {/* Add more routes here */}
            </Routes>
        </Router>
    );
}

export default AppRouter;
