import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { getUserProfile, updateUserProfile, updatePassword } from '../utils/api';

function Profile() {
  const { auth } = useAuth();
  const [profile, setProfile] = useState({
    first_name: '',
    email: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserProfile = useCallback(async () => {
    if (!auth || !auth.token) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      const userData = await getUserProfile(auth.token);
      setProfile(userData);
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
      setMessage('Failed to load profile. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  const handleInputChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!auth || !auth.token) {
      setMessage('You must be logged in to update your profile.');
      return;
    }
    try {
      await updateUserProfile(auth.token, {
        first_name: profile.first_name
      });
      setIsEditing(false);
      setMessage('Profile updated successfully!');
      fetchUserProfile();
    } catch (error) {
      console.error('Failed to update profile:', error);
      setMessage('Failed to update profile. Please try again.');
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    try {
      await updatePassword(auth.token, newPassword, confirmPassword);
      setIsChangingPassword(false);
      setNewPassword('');
      setConfirmPassword('');
      setMessage('Password updated successfully!');
    } catch (error) {
      console.error('Failed to update password:', error);
      setMessage('Failed to update password. Please try again.');
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <motion.div
          className="w-16 h-16 border-t-4 border-indigo-500 border-solid rounded-full animate-spin"
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        />
      </div>
    );
  }

  if (!auth || !auth.token) {
    return <div>Please log in to view your profile.</div>;
  }

  return (
    <motion.div
      className="min-h-screen bg-gradient-to-b from-purple-100 to-indigo-100 py-12 px-4 sm:px-6 lg:px-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <style jsx>{`
        input:focus {
          outline: none;
          box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
        }
      `}</style>
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="px-4 py-5 sm:px-6 bg-gradient-to-r from-purple-600 to-indigo-600">
          <h2 className="text-2xl leading-6 font-bold text-white">Profile</h2>
        </div>
        <div className="px-4 py-5 sm:p-6">
          {message && (
            <motion.div
              className="mb-4 p-2 bg-green-100 text-green-700 rounded"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {message}
            </motion.div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={profile.email}
                disabled
                className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md shadow-sm text-sm cursor-not-allowed"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                Full Name
              </label>
              <input
                type="text"
                name="first_name"
                id="first_name"
                value={profile.first_name}
                onChange={handleInputChange}
                // readOnly={!isEditing}
                className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm ${
                  isEditing ? 'bg-white' : 'bg-gray-100'
                }`}
              />
            </div>
            {isEditing ? (
              <motion.button
                type="submit"
                className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-2 px-4 rounded-md hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Save Changes
              </motion.button>
            ) : (
              <motion.button
                type="button"
                onClick={() => setIsEditing(true)}
                className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-2 px-4 rounded-md hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Edit Profile
              </motion.button>
            )}
          </form>
          <div className="mt-6">
            <motion.button
              type="button"
              onClick={() => setIsChangingPassword(!isChangingPassword)}
              className="w-full bg-gray-200 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Change Password
            </motion.button>
          </div>
          {isChangingPassword && (
            <motion.form
              onSubmit={handlePasswordChange}
              className="mt-4"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="mb-4">
                <label htmlFor="new_password" className="block text-sm font-medium text-gray-700">
                  New Password
                </label>
                <input
                  type="password"
                  name="new_password"
                  id="new_password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="confirm_password" className="block text-sm font-medium text-gray-700">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  name="confirm_password"
                  id="confirm_password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm"
                  required
                />
              </div>
              <motion.button
                type="submit"
                className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-2 px-4 rounded-md hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Update Password
              </motion.button>
            </motion.form>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default React.memo(Profile);
